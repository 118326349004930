<template>
    <div>
        <Navbar page="Profissionais no Projeto" link="/cadastros" nameLink="Cadastros" video="https://www.youtube.com/watch?v=TtljzS_OSCE" pdf="/itens_exclusivos.pdf" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">            
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-2">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-6">
                                <label for="nome" class="block text-sm font-medium">Nome *</label>
                                <input v-model="form.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <!-- <div class="col-span-12 md:col-span-3">
                                <label for="funcao" class="block text-sm font-medium">Função</label>
                                <input v-model="form.funcao" type="text" name="funcao" id="funcao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>  -->
                            <div class="col-span-12 md:col-span-3">
                                <label for="telefone" class="block text-sm font-medium">Telefone</label>
                                <input v-model="form.telefone" type="text" name="telefone" id="telefone" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                        </div>
                        <div class="grid grid-cols-12 gap-6 mt-3">
                            <div v-if="form.isDpo" class="col-span-12 md:col-span-3">
                                <label for="cpfCnpj" class="block text-sm font-medium">CPF / CNPJ</label>
                                <input v-model="form.cpfCnpj" type="text" name="cpfCnpj" id="cpfCnpj" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-4">
                                <label for="email" class="block text-sm font-medium">Email *</label>
                                <input v-model="form.email" type="email" name="email" id="name" autocomplete="off" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div v-if="!alterarPassword" class="col-span-12 md:col-span-4">
                                <button @click="alterarPassword = !alterarPassword" type="button" class="inline-flex justify-center py-2 px-6 md:mt-7 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-500">
                                    Alterar a senha
                                </button>
                            </div>
                            <div v-if="alterarPassword" class="col-span-12 md:col-span-4">
                                <label for="password" class="block text-sm font-medium">Senha *</label>
                                <input v-model="form.password" type="password" name="password" id="password" autocomplete="off" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <!-- <div class="col-span-12 md:col-span-12">
                                <label for="isDpo" class="block text-sm font-medium">
                                    <input type="checkbox" class="rounded-sm" id="isDpo" v-model="form.isDpo">
                                    <span class="ml-2"> DPO</span>
                                </label>
                            </div> -->
                            <div class="col-span-12 md:col-span-12">
                                <label for="receberEmail" class="block text-sm font-medium">
                                    <input type="checkbox" class="rounded-sm" id="receberEmail" v-model="form.receberEmail">
                                    <span class="ml-2"> Receber e-mail</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 pb-4 mt-3">
                        <label for="telefone" class="block text-sm font-medium">Foto Perfil</label>
                        <small class="text-xs font-medium">Tamanho recomendado 512x512</small>
                        <div class="grid grid-cols-12 gap-6 mt-3">
                            <div class="col-span-12 md:col-span-4">
                                <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="updateFoto">
                                </vue-dropzone>
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <img class="w-32 h-32 rounded-full" v-if="this.form.foto && this.form.foto.filename" :src="`${this.url_api}/upload?mimetype=image/jpeg&filename=${this.form.foto.filename}&folder=empresas`"/>
                            </div>
                        </div>
                    </div>
                    <div v-if="$store.state.user.consultor" class="px-5 pb-4 mt-3">
                        <label for="telefone" class="block text-sm font-medium">Empresas</label>
                        <div class="grid grid-cols-12 gap-6 mt-3">
                            <div class="col-span-12 md:col-span-6 2xl:col-span-4">
                                <div v-for="item in empresas" :key="item._id" class="border border-gray-200 px-6 py-5">
                                    <div class="grid grid-cols-12 gap-3">
                                        <div class="col-span-12 md:col-span-6">
                                            <img class="h-12 justify-self-center mb-2" v-if="item && item.logo.filename" :src="`${url_api}/upload?mimetype=image/jpeg&filename=${item.logo.filename}&folder=empresas`"/>
                                            <span v-else class="text-base font-semibold mb-2">{{ item.nome }}</span>
                                        </div>
                                        <div class="col-span-12 md:col-span-6 md:mt-1">
                                            <label for="empresas" class="block text-sm font-medium">
                                                <input :checked="form.empresas.indexOf(item._id) > -1" @change="(e) => selecionadosControl(item._id, e)" type="checkbox" class="rounded-sm" id="empresas">
                                                <span class="ml-2">
                                                    Responsável
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    components: {
        vueDropzone
    },
    data() {
        return {
            route: 'profissionais',
            alterarPassword: this.$route.params.id ? false : true,
            form: {
                name: '',
                email: '',
                cpfCnpj: '',
                telefone: '',
                password: '',
                empresas: [],
                funcao: '',
                isDpo: false,
                receberEmail: true,
                foto: null,
            },
            empresas: [],
            funcoes: [],
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste aqui',
                dictRemoveFile: 'Remover'
            }),
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$store.commit('setProName', this.form.nome)
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.push({path: '/cadastros/profissionais'});
            } else {
                if (req.data.perguntarSeQuerReativar) {
                    this.$confirm({
                        title: 'Esse profissional foi excluído',
                        message: `Deseja Reativar esse profissional no projeto?`,
                        button: {
                            no: 'Não',
                            yes: 'Sim',
                        },
                        callback: async confirm => {
                            if(!confirm) return;
                            await this.$http[method](`/v1/${this.route}/reativar`, this.form);
                            this.$vToastify.success("Profissional reativado com sucesso!");
                            this.$router.push({path: '/cadastros/profissionais'});
                        }
                    });
                } else {
                    this.$vToastify.error(req.data.err);
                }
            }
        },
        updateFoto(file, response) {
            this.form.foto = response.file;
        },
        selecionadosControl(id, e){
            if (this.form.empresas.indexOf(id) > -1 && !e.target.checked) {
                this.form.empresas.splice(this.form.empresas.indexOf(id), 1);
            } else {
                this.form.empresas.push(id);
            }
        }
    },
    async beforeMount() {
        const id = this.$route.params.id;

        const listReq = await this.$http.post(`/v1/empresas/list`, { all: true });
        this.empresas = listReq.data.data;

        const funcReq = await this.$http.post(`/v1/funcoes/list`, {all: true});
        this.funcoes = funcReq.data.data;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
        }
    },
}
</script>